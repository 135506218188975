import { useState, useEffect } from 'react';
import Web3 from 'web3';
import Web3Modal from 'web3modal';

import { getChainData } from '../helpers/utilities';

const DEFAULT_CHAIN_ID = +process.env.REACT_APP_DEFAULT_CHAIN_ID;

const useWeb3Modal = () => {
    const [web3, setWeb3] = useState(null);
    const [provider, setProvider] = useState(null);
    const [chainId, setChainId] = useState(DEFAULT_CHAIN_ID);
    const [address, setAddress] = useState(undefined);
    const [chainData, setChainData] = useState(null);

    const defaultChainData = getChainData(DEFAULT_CHAIN_ID);

    const getNetwork = () => getChainData(chainId)?.network;

    // const getProviderOptions = () => {
    //     const infuraId = process.env.REACT_APP_INFURA_ID;
    //     const providerOptions = {
    //         walletconnect: {
    //             package: WalletConnect,
    //             options: {
    //                 infuraId
    //             }
    //         },
    //         torus: {
    //             package: Torus
    //         },
    //         walletlink: {
    //             package: WalletLink,
    //             options: {
    //                 appName: 'Web3Modal Example App',
    //                 infuraId
    //             }
    //         }
    //     };
    //     return providerOptions;
    // };

    const [web3Modal, setWeb3Modal] = useState(
        new Web3Modal({
            network: getNetwork(),
            cacheProvider: true
            // providerOptions: getProviderOptions()
        })
    );

    const resetApp = async () => {
        if (web3 && web3.currentProvider && web3.currentProvider.close) {
            await web3.currentProvider.close();
        }
        await web3Modal.clearCachedProvider();

        setWeb3(null);
        setProvider(null);
        setAddress('');
        setChainId(DEFAULT_CHAIN_ID);
    };

    const subscribeProvider = async (provider) => {
        if (!provider.on) {
            return;
        }
        provider.on('close', () => {
            resetApp();
        });
        provider.on('accountsChanged', async (accounts) => {
            setAddress(accounts[0]);
        });
        provider.on('chainChanged', async (chainId) => {
            setChainId(Web3.utils.hexToNumber(chainId));
        });
    };

    function initWeb3(provider) {
        const web3 = new Web3(provider);

        web3.eth.extend({
            methods: [
                {
                    name: 'chainId',
                    call: 'eth_chainId',
                    outputFormatter: web3.utils.hexToNumber
                }
            ]
        });

        return web3;
    }

    const onConnect = async () => {
        let providerData;
        try {
            providerData = await web3Modal.connect();
        } catch (e) {
            return;
        }
        await providerData.enable();
        const web3Data = initWeb3(providerData);
        const accounts = await web3Data.eth.getAccounts();
        const addressData = accounts[0];
        const chainIdData = await web3Data.eth.chainId();

        setWeb3(web3Data);
        setProvider(providerData);
        setAddress(addressData);
        setChainId(chainIdData);
    };

    useEffect(() => {
        if (web3Modal.cachedProvider) {
            onConnect();
        }
    }, [web3Modal]);

    useEffect(async () => {
        if (web3 && provider) {
            await subscribeProvider(provider);
        }
    }, [web3, provider]);

    useEffect(() => {
        if (chainId) {
            setChainData(getChainData(chainId));
        }
    }, [chainId]);

    return {
        web3,
        provider,
        chainId,
        chainData,
        defaultChainData,
        address,
        DEFAULT_CHAIN_ID,
        onConnect
    };
};

export default useWeb3Modal;
