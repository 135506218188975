import { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography } from '@mui/material';
import useWeb3Modal from 'hooks/useWeb3Modal';

const ConnectWallet = () => {
    const { address, chainId, chainData, defaultChainData, DEFAULT_CHAIN_ID, onConnect } = useWeb3Modal();
    const connected = address !== undefined;
    const [isOpenDlg, setOpenDlg] = useState(false);

    useEffect(() => {
        if (chainId && chainId !== DEFAULT_CHAIN_ID) {
            setOpenDlg(true);
        } else {
            setOpenDlg(false);
        }
    }, [chainId]);

    const handleConnect = async () => {
        onConnect();
    };

    const handleCloseDlg = () => {
        setOpenDlg(false);
    };

    return (
        <>
            {connected && <Button>{address}</Button>}
            {!connected && (
                <Button onClick={handleConnect} variant="contained">
                    Connect
                </Button>
            )}
            <Dialog open={isOpenDlg} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Wrong Network</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {chainData && (
                            <Typography>
                                You need to connected to <b>{defaultChainData?.name}</b> to use this app, but you are currently connected to
                                &nbsp;<b>{chainData.name}</b>
                            </Typography>
                        )}
                        {!chainData && (
                            <Typography>
                                You need to connected to <b>{defaultChainData?.name}</b> to use this app. Please switch your network to
                                continue
                            </Typography>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleCloseDlg} autoFocus>
                        Agree
                    </Button> */}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ConnectWallet;
