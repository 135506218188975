// assets
import { IconDashboard, IconCirclePlus, IconList, IconBox } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconCirclePlus, IconList, IconBox };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'mint-kryptomon',
            title: 'Mint Kryptomon',
            type: 'item',
            url: '/dashboard/mint-kryptomon',
            icon: icons.IconCirclePlus,
            breadcrumbs: false
        },
        {
            id: 'mint-kryptomonV2',
            title: 'Mint Kryptomon V2',
            type: 'item',
            url: '/dashboard/mint-kryptomonV2',
            icon: icons.IconCirclePlus,
            breadcrumbs: false
        },
        {
            id: 'manage-kryptomons',
            title: 'Manage Kryptomons',
            type: 'item',
            url: '/dashboard/manage-kryptomons',
            icon: icons.IconList,
            breadcrumbs: false
        },
        {
            id: 'manage-kryptomonsV2',
            title: 'Manage Kryptomons V2',
            type: 'item',
            url: '/dashboard/manage-kryptomonsV2',
            icon: icons.IconList,
            breadcrumbs: false
        },
        {
            id: 'add-items',
            title: 'Add In-Game Items',
            type: 'item',
            url: '/dashboard/add-items',
            icon: icons.IconBox,
            breadcrumbs: false
        },
        {
            id: 'simulate-breeding',
            title: 'Simulate Breeding',
            type: 'item',
            url: '/dashboard/simulate-breeding',
            icon: icons.IconBox,
            breadcrumbs: false
        },
        {
            id: 'nftItems',
            title: 'NFT Items',
            type: 'item',
            url: '/dashboard/nft-items',
            icon: icons.IconBox,
            breadcrumbs: false
        },
        {
            id: 'orders',
            title: 'Orders',
            type: 'item',
            url: '/dashboard/orders',
            icon: icons.IconBox,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
