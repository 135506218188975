import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const MintKryptmon = Loadable(lazy(() => import('views/dashboard/mint-kryptomon')));
const MintKryptmonV2 = Loadable(lazy(() => import('views/dashboard/mint-kryptomonV2')));
const ManageKryptomons = Loadable(lazy(() => import('views/dashboard/manage-kryptomons')));
const ManageKryptomonsV2 = Loadable(lazy(() => import('views/dashboard/manage-kryptomonsV2')));
const SimulateBreeding = Loadable(lazy(() => import('views/dashboard/simulate-breeding')));
const NftItems = Loadable(lazy(() => import('views/dashboard/nft-items')));
const Orders = Loadable(lazy(() => import('views/dashboard/orders')));
const AddItems = Loadable(lazy(() => import('views/dashboard/add-items')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/mint-kryptomon',
            element: <MintKryptmon />
        },
        {
            path: '/dashboard/mint-kryptomonV2',
            element: <MintKryptmonV2 />
        },
        {
            path: '/dashboard/manage-kryptomons',
            element: <ManageKryptomons />
        },
        {
            path: '/dashboard/manage-kryptomonsv2',
            element: <ManageKryptomonsV2 />
        },
        {
            path: '/dashboard/simulate-breeding',
            element: <SimulateBreeding />
        },
        {
            path: '/dashboard/add-items',
            element: <AddItems />
        },
        {
            path: '/utils/util-typography',
            element: <UtilsTypography />
        },
        {
            path: '/utils/util-color',
            element: <UtilsColor />
        },
        {
            path: '/utils/util-shadow',
            element: <UtilsShadow />
        },
        {
            path: '/icons/tabler-icons',
            element: <UtilsTablerIcons />
        },
        {
            path: '/icons/material-icons',
            element: <UtilsMaterialIcons />
        },
        {
            path: '/dashboard/nft-items',
            element: <NftItems />
        },
        {
            path: '/dashboard/orders',
            element: <Orders />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
